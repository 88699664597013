:root {
    --green: #0dc10d;
    --orange: #ff981f;
    --red: #8f2320;
    --grey: #5e5f59;
    --cyan: #11fafa;
    --yellow: #f1ff18;
    --white: #ffffff;
}

@keyframes spinner {
    from {transform: rotate(0deg)}
    to {transform: rotate(360deg)}
}

@keyframes glow3 {
    0%{
      color: #FFFFFF;
    } 25%{
      color: #00B000;
    } 50% {
      color: #FFFFFF;
    } 67.5%{
      color: #00FFFF;
    } 75% {
      color: #0000FF;
    } 100% {
      color: #FFFFFF;
    }
}

@font-face {
    font-family:"Runescape Chat Font Regular";
    src: url("./resources/font/runescape_chat_font.eot?") format("eot"),
         url("./resources/font/runescape_chat_font.woff") format("woff"),
         url("./resources/font/runescape_chat_font.ttf") format("truetype"),
        url("./resources/font/runescape_chat_font.svg#Runescape-Chat-Font") format("svg");
    font-weight:normal;font-style:normal;
}


body, html {
    font-family: 'Runescape Chat Font Regular',monospace;
    font-size: 19px;
    text-shadow: 1px 1px #000;
    padding: 0;
    margin: 0;
    width: 100%;
}
body {
    background: url('./img/page-wrap.jpg') repeat-y center top, #000;
}

.wrap {
    max-width: 815px;
    margin: 0 auto;
    padding: 50px 0px;
}

.player {
    width: 70%;
    max-width: 260px;
    min-width: 235px;
    background: url('./img/Background.png');
    background-size: 100% 100%;
    margin: 0 auto;
    padding: 15px;
    position: relative;
}

.player::before, .player::after {
    content: '';
    height: 100%;
    width: 36px;
    top: 0;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center;
    position: absolute;
    background: url('./img/Pillar.png');
}

.player::before {
    left: -24px;
}
.player::after {
    right: -24px;
}
.modeSelect {
    float: right;
    padding-right: 5px;
}

.modeSelect::after {
    clear: both;
}

.modeSelect button {
    width: 50px;
    height: 34px;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    border: none;
    outline: none;
}

.modeSelect .auto {
    background: url('./img/buttons/Auto.png');
}

.modeSelect .auto.active {
    background: url('./img/buttons/AutoSelected.png');
}

.modeSelect .manual {
    background: url('./img/buttons/Man.png');
}

.modeSelect .manual.active {
    background: url('./img/buttons/ManSelected.png');
}

.modeSelect .loop {
    background: url('./img/buttons/Loop.png');
}

.modeSelect .loop.active {
    background: url('./img/buttons/LoopSelected.png');
}


.playPause, .button {
    background: url('./img/button.png');
    background-size: 100% 100%;
    color: var(--orange);
    position: relative;
    outline: none;
    padding: 0;
    margin: 0;
    display: inline-table;
    cursor: pointer;
    text-transform: uppercase;
    font-family: 'Runescape Chat Font Regular', monospace;
    font-size: 25px;
}

.playPause p, .button p {
    padding: 0;
    margin: 0;
    width: 75px;
    height: 50px;
    display: table-cell;
    vertical-align: middle;
}

.playPause.loading p {
    animation-name: spinner;
    animation-duration: .5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: forwards;
}

.playing {
    color: var(--orange);
    margin: 10px auto;
    padding: 0px 15px;
    padding-top: 10px;
    line-height: 1.2;
}

.playing span {
    color: var(--green);
}
.songs {
    background: url('./img/border-background-old.jpg');
    background-size: 100% 100%;
    padding: 12px;
}


.player ul {
    height: 250px;
    overflow-y: scroll;
    list-style-type: none;
    margin: 0;
    padding: 5px 15px; 
}

.player ul::-webkit-scrollbar {
    width: unset;
}

.player ul::-webkit-scrollbar-track {
    background: url('./img/scrollbar/track.png');
    background-size: contain;
}

.player ul::-webkit-scrollbar-thumb {
    background: url('./img/scrollbar/thumb.png');
    background-repeat: no-repeat;
    height: unset;
    background-size: contain;
}

.player ul::-webkit-scrollbar-button:vertical:increment {
    background: url('./img/scrollbar/decrement.png');
    background-size: contain;
}

.player ul::-webkit-scrollbar-button:vertical:decrement {
    background: url('./img/scrollbar/increment.png');
    background-size: contain;
}

.player ul::-webkit-scrollbar-button:horizontal:end:increment,
.player ul::-webkit-scrollbar-button:horizontal:start:decrement {
    display: block;
}

@media only screen and (max-device-width: 687px) {

    .player ul::-webkit-scrollbar-thumb,
    .player ul::-webkit-scrollbar,
    .player ul::-webkit-scrollbar-track {
      display: none;
    }
  
  }
.player ul a {
    text-decoration: none;
}

.player ul li {
    margin: 0;
    padding: 1px 0px;
    color: var(--green);
    cursor: pointer;
}

.player ul li:hover, .player ul li:focus {
    color: var(--white)
}

.player ul li p {
    margin: 0;
}

.contextMenu {
    position: absolute;
    z-index: 1;
    padding: 0;
    background-color: var(--grey);
    height: unset;
    cursor: pointer;
}

.contextMenu .header {
    margin: 0px;
    background: #000;
    color: var(--grey);
    padding: 2px;
}

.contextMenu .header p {
    margin: 0;
    padding: 0;
}
.contextMenu ul {
    border: 2px solid #000;
    list-style-type: none;
    margin: 0;
    padding: 2px 3px;
    height: unset;
    overflow: auto;
}

.contextMenu ul li {
    margin: 0 !important;
    padding: 0;
    color: #fff;
    height: unset;
}

.contextMenu ul li:hover {
    color: var(--yellow);
}

.contextMenu ul li span {
    color: var(--cyan);
}
.controls {
    text-align: center;
}
.controls button {
    text-align: center;
    margin: 15px auto;
}

.upNext ul {
    list-style-type: none;
    margin: 15px auto;
    display: block;
    max-width: 600px;
    width: 80%;
    text-align: center;
    padding: 0;
}

.upNext ul div {
    width: fit-content;
    margin: 0 auto;
}

.upNext .copy {
    width: 200px;
    color: var(--cyan);
    cursor: pointer;
    margin: 0 auto;
    text-align: center;
}

.upNext .copy:hover {
    animation: glow3 5s infinite;
}

.upNext .copy .material-icons {
    font-size: inherit;
    vertical-align: middle;
}


.shuffle {
    text-align: center;
    margin: 15px;
}

.shuffle p {
    display: inline-block;
    color: var(--white);
    margin: 0;
}
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 28px;
    vertical-align: middle;
    margin: 0px 10px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .2s;
    transition: .2s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .2s;
    transition: .2s;
  }
  
  input:checked + .slider {
    background-color: var(--cyan);
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px var(--cyan);
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(30px);
    -ms-transform: translateX(30px);
    transform: translateX(30px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

.upNext h1 {
    color: var(--orange);
    text-align: center;
    margin-bottom: 0px;
}
.upNext ul li {
    color: var(--green);
    cursor: pointer;
    width: fit-content;
    margin: 0 auto;
}


.instructions {
    color: var(--red);
    text-align: center;
}
.instructions h1 {
    cursor: pointer;
}

.instructions p {
    color: var(--white);
    width: 90%;
    max-width: 600px;
    margin: 20px auto;
    line-height: 1.2
}

.instructions p .code {
    background-color: var(--red);
    color: var(--white);
    border-radius: 5px;
    padding: 0px 5px;
    font-style: normal;
    white-space: nowrap;    
}

.instructions p a {
    color: var(--cyan);
}
.hidden {
    display: none;
}

.Toastify__toast::before {
    content: '';
    background: url('resources/gnome.png');
    width: 50px;
    background-size: contain;
    background-repeat: no-repeat;
}

.Toastify__toast--success, .Toastify__toast--error {
    background-color: #5a5232 !important;
}

.Toastify__toast-body{
    font-family: 'Runescape Chat Font Regular',monospace !important;
    text-align: left;
}